.questionComp{
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  max-width: 600px;
  width: 100%;
  text-align: center;
}
.statement{
  color: #ffffff;
  font-weight: 600;
  line-height: 1.5;
  text-align: center;
}
.question{
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin: 0 15px;
}
.questions{
  align-items: center;
  display: flex;
  justify-content: center;
  margin: 30px 0 15px;
}
.option{
  outline: none;
  align-items: center;
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  justify-content: center;
  transition: all .1s ease-in-out;
}
.decisionmobile{
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-top: 15px;
}


.notselected{
  background-color: #5c6bc0;
}
.selectedR{
  background-color: #c71585;
}
.selectedL{
  background-color: #1e90ff;
}
.selectedM{
  background-color: #9b9faa;;
}
.selectStyleR{
  border: 4px solid #c71585;
}
.selectStyleL{
  border: 4px solid #1e90ff;
}
.selectMiddle{
  border: 4px solid #9b9faa;
  flex: 0 0 30px;
  height: 30px;
  max-width: 30px;
}
.selectSmall{
  flex: 0 0 35px;
  height: 35px;
  max-width: 35px;
}
.selectBig{
  flex: 0 0 40px;
  height: 40px;
  max-width: 40px;
}
.select{
    align-items: center;
    border-radius: 50%;
    cursor: pointer;
    display: flex;
    justify-content: center;
    transition: all .1s ease-in-out;
}

.nocolor{
  background-color: #ffffff;
}
.outtype{
  background-color: #ea5550;
}
.intype{
  background-color: #1e90ff;
}


.mobilechart{
  height: 380px;
  width: 380px;
}
.chart{
  height: 430px;
  width: 430px;
}
